<template>
  <div>
    <div style="font-size:16px" v-if="baseInfoEditable">
      <span class="m-r-md">{{item.contentType | typePipe}}</span>
      <span class="m-r-md">{{item.categoryName}}</span>
      <span class="m-r-md">{{item.minLevel}} - {{item.maxLevel}} 级</span>
    </div>

    <div v-for="mode in item.readModuleList" :key="mode.moduleType" style="padding-top:.5em">
      <div style="display:inline-block;width:10em">
        <b>{{readModes[mode.moduleName]}}</b>
        <span v-if="mode.modulePercent">( {{mode.modulePercent}}% )</span>
        <span v-if="mode.readDefault" class="text-success">默认</span>
      </div>
      <span
        v-for="(order,i) in mode.learnOrderList"
        :key="i"
        style="margin-right:2em"
      >{{order.name}} {{order.count}}遍</span>
    </div>
  </div>
</template>

<script>
import { readModes } from "./readingModeSettings";
export default {
  props: {
    item: {},
    baseInfoEditable: {
      default: true
    },
  },
  data() {
    return {
      readModes: readModes
    };
  }
};
</script>

<style>
</style>
<template>
  <div style="padding: 2em 2em 0 0">
    <Form :model="formData" :label-width="100">
      <FormItem label="类型" v-if="baseInfoEditable">
        <FirstCodeSelect
          :withAll="false"
          :typeName="laun"
          v-model="formData.contentType"
        />
        <Category
          style="display: inline-block"
          :firstCode="formData.contentType"
          :hiddenThirdCategory="true"
          :secondCode.sync="formData.category"
        />
      </FormItem>
      <FormItem label="等级区间" v-if="baseInfoEditable">
        <LevelRange :minLevel.sync="formData.minLevel" :maxLevel.sync="formData.maxLevel" />
      </FormItem>
      <FormItem label="模式设置">
        <CheckboxGroup v-model="selectModes">
          <div v-for="(name, code) in readModes" :key="code" class="flex-top">
            <Checkbox style="width: 7em" :label="code">{{ name }}</Checkbox>
            <div
              v-if="
                selectModes.includes(code) &&
                stepsAddedOfMode[code].selects.length
              "
            >
              <div v-for="(step, i) in stepsAddedOfMode[code].steps" :key="i">
                <Select v-model="step.playCode" style="width: 100px">
                  <Option
                    v-for="stp in stepsAddedOfMode[code].selects"
                    :key="stp.code"
                    :value="stp.code"
                    >{{ stp.name }}</Option
                  >
                </Select>
                <Input
                  style="width: 80px"
                  placeholder="遍数"
                  type="number"
                  v-model.number="step.count"
                />
                <Icon
                  class="text-error"
                  type="md-remove-circle"
                  @click="stepsAddedOfMode[code].steps.splice(i, 1)"
                  style="font-size: 1.15em; margin-left: 0.5em; cursor: pointer"
                />
              </div>
              <div
                class="text-success"
                style="padding-left: 1em; cursor: pointer"
                @click="addStepToMode(code)"
              >
                继续
                <Icon
                  type="md-add-circle"
                  style="font-size: 1.15em; margin-left: 0.5em"
                />
              </div>
            </div>
          </div>
        </CheckboxGroup>
      </FormItem>
      <FormItem label="默认模式">
        <Checkbox style v-model="defaultModeInfo.useRate"
          >是否按比例设置</Checkbox
        >
        <div v-if="defaultModeInfo.useRate">
          <div class="flex-middle">
            <div
              v-for="mode in selectModes.filter(
                (ele) => defaultModeInfo.rates[ele] !== undefined
              )"
              :key="mode"
            >
              {{ readModes[mode] }}：
              <Input
                style="width: 80px; margin-right: 1em"
                placeholder="比例"
                type="number"
                v-model.number="defaultModeInfo.rates[mode]"
              />
            </div>
          </div>

          <div v-if="defaultRates.length === 2">
            选择优先推送模式：
            <Select v-model="defaultModeInfo.previous" style="width: 100px">
              <Option v-for="mode in defaultRates" :key="mode" :value="mode">{{
                readModes[mode]
              }}</Option>
            </Select>
          </div>
          <div v-else-if="defaultRates.length > 2" class="text-error">
            最多只能有2个默认模式
          </div>
        </div>
        <div v-else>
          请选择默认模式：
          <Select v-model="defaultModeInfo.default" style="width: 100px">
            <Option v-for="mode in selectModes" :key="mode" :value="mode">{{
              readModes[mode]
            }}</Option>
          </Select>
        </div>
      </FormItem>
      <FormItem>
        <Poptip title="确定要放弃吗？" confirm @on-ok="cancel">
          <Button style="margin-right: 2em">取消</Button>
        </Poptip>
        <Button @click="submit" type="primary">提交</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import {
  getModeSteps,
  submitConfig,
  readModes,
  readModesOrder,
  percentModes
} from "./readingModeSettings";
import FirstCodeSelect from "./FirstCodeSelect";
export default {
  props: {
    editData: {},
    baseInfoEditable: {
      default: true,
    },
    noSubmit: {
      default: false,
    },
  },
  data() {
    return {
      readModes: readModes,
      //   ....
      selectModes: [],
      stepsAddedOfMode: {},
      defaultModeInfo: {
        default: "",
        useRate: true,
        rates: Object.fromEntries(percentModes.map(ele=>[ele,''])),
        previous: "",
      },
      //   ....
      formData: {
        contentType: "",
        category: "",
        minLevel: null,
        maxLevel: null,
        id: "",
        typeName: "",
      },
    };
  },
  components: { FirstCodeSelect },
  computed: {
    defaultRates() {
      let rates = [];
      for (let mode of this.selectModes) {
        if (this.defaultModeInfo.rates[mode]) {
          rates.push(mode);
        }
      }
      return rates;
    },
    laun() {
      return this.$store.getters.getLaun;
    },
  },
  methods: {
    validate() {
      if (this.baseInfoEditable) {
        if (!this.formData.contentType || !this.formData.category) {
          this.$Message.warning("请选择完类型和二级分类！");
          return false;
        }
        this.formData.minLevel?.[this.formData.minLevel = Math.floor(this.formData.minLevel)];
        this.formData.maxLevel?.[this.formData.maxLevel = Math.floor(this.formData.maxLevel)];
        if (
         
          (this.formData.maxLevel??-1) < this.formData.minLevel ||
          (this.formData.minLevel??-1) < 0
        ) {
          this.$Message.warning("请填写正确的等级区间！");
          return false;
        }
      }

      if (!this.selectModes.length) {
        this.$Message.warning("请先设定模式！");
        return false;
      }
      // ===== 验证模式的步骤是否正确：
      for (let key of this.selectModes) {
        if (!this.stepsAddedOfMode[key].selects.length) {
          continue;
        }
        let steps = this.stepsAddedOfMode[key].steps;
        if (!steps.length) {
          this.$Message.warning(`${this.readModes[key]}模式必须要设置步骤！`);
          return false;
        }
        if (steps.some((ele) => !ele.playCode || !ele.count)) {
          this.$Message.warning(
            `${this.readModes[key]}模式设置的步骤信息不完整或错误！`
          );
          return false;
        }
      }
      //   ===== 验证默认模式的规则：
      let rateModes = Array.from(
        Object.keys(this.defaultModeInfo.rates)
      ).filter((ele) => this.selectModes.includes(ele));
      if (!rateModes.length) {
        this.defaultModeInfo.useRate = false;
      }

      if (this.defaultModeInfo.useRate) {
        let count = 0;
        let total = 0;
        for (let mode of rateModes) {
          if (this.defaultModeInfo.rates[mode]) {
            count++;
            total += this.defaultModeInfo.rates[mode];
          }
        }
        if (count > 2) {
          this.$Message.warning("最多只能有2个百分比的默认模式！");
          return false;
        }
        if (count === 0) {
          this.$Message.warning("请按百分比设置默认模式");
          return false;
        }
        if (total !== 100) {
          this.$Message.warning("默认模式配置的百分比相加必须为100！");
          return false;
        }
        if (count > 1) {
          if (
            !this.defaultModeInfo.previous ||
            !this.defaultRates.includes(this.defaultModeInfo.previous)
          ) {
            this.$Message.warning("请选择优先推送的默认模式！");
            return false;
          }
        }
      } else {
        if (!this.defaultModeInfo.default) {
          this.$Message.warning("请设定默认模式！");
          return false;
        }
      }

      return true;
    },
    cancel() {
      this.$emit("close", false);
    },
    assemble() {
      // 由 selectModes defaultModeInfo stepsAddedOfMode 生成 readModuleList
      let modeList = [];
      for (let mode of this.selectModes) {
        let readDefault;
        let modulePercent;
        if (this.defaultModeInfo.useRate) {
          modulePercent = this.defaultModeInfo.rates[mode] || 0;
          readDefault =
            100 === modulePercent ||
            (this.defaultModeInfo.previous === mode && modulePercent > 0);
        } else {
          modulePercent = 0;
          readDefault = this.defaultModeInfo.default === mode;
        }
        modeList.push({
          moduleType: readModesOrder.indexOf(mode),
          moduleName: mode,
          learnOrderList: this.stepsAddedOfMode[mode].steps.map((ele) => ({
            count: ele.count,
            name: this.stepsAddedOfMode[mode].selects.find(
              (x) => x.code === ele.playCode
            ).name,
            orderCode: ele.playCode,
          })),
          modulePercent,
          readDefault,
        });
      }
      this.formData.typeName = this.laun;
      return Object.assign({ readModuleList: modeList }, this.formData);
    },
    factory() {
      if (!this.editData) {
        return;
      }
      this.formData.contentType = this.editData.contentType;
      this.formData.category = this.editData.category;
      this.formData.minLevel = this.editData.minLevel;
      this.formData.maxLevel = this.editData.maxLevel;
      this.formData.id = this.editData.id;
      let rates = this.defaultModeInfo.rates;
      for (let {
        moduleName,
        readDefault,
        modulePercent,
        learnOrderList,
      } of this.editData.readModuleList) {
        this.selectModes.push(moduleName);
        if (undefined !== rates[moduleName]) {
          rates[moduleName] = modulePercent;
        }
        if (readDefault) {
          this.defaultModeInfo.useRate = !!modulePercent;
          modulePercent
            ? (this.defaultModeInfo.previous = moduleName)
            : (this.defaultModeInfo.default = moduleName);
        }
        // 还剩最后一步，初始化模式的步骤：
        if (!learnOrderList.length) {
          continue;
        }
        this.stepsAddedOfMode[moduleName].steps = learnOrderList.map(
          ({ orderCode, count }) => ({
            count,
            playCode: orderCode,
          })
        );
      }
    },
    submit() {
      if (!this.validate()) {
        return;
      }
      let data = this.assemble();
      if (this.noSubmit) {
        this.$emit("finish", data);
      } else {
        submitConfig(data).then(() => {
          this.$emit("close", true);
        });
      }
    },

    addStepToMode(modeCode) {
      this.stepsAddedOfMode[modeCode].steps.push({
        count: "",
        playCode: "",
      });
    },
  },
  beforeMount() {
    for (let mode of Object.keys(this.readModes)) {
      this.$set(this.stepsAddedOfMode, mode, {
        steps: [],
        selects: [],
      });
      getModeSteps(mode).then((res) => {
        this.stepsAddedOfMode[mode].selects = res;
      });
    }
    this.factory();
  },
};
</script>

<style scoped>
.flex-top {
  display: flex;
  align-items: flex-start;
}
</style>